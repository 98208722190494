import * as React from "react";
import { BulkDeleteButton, TextField, DateField, WithRecord, Button, Datagrid, ListContextProvider, ReferenceManyField, FunctionField, List, ReferenceField, TextInput, SelectInput, ReferenceInput, AutocompleteInput, SimpleForm, NumberInput, Pagination, BulkExportButton, ReferenceArrayInput } from "react-admin";
import {countries} from "../../utils/countries";
import {Page} from "../../utils/Page";
import {TenantField} from "../../utils/tenant";
import {CountryInput} from "../../utils/CountryInput";
import {DepartmentInput, ProductInput} from "../crud/CaseInputFields";
import {toChoices} from "../../common/common-utils";
import {INSTRUCTION_DO_NOT_PAY, INSTRUCTION_PAY, INSTRUCTION_WAIT} from "../annuities/Annuities";
import {CaseRefField} from "../crud/CaseRefField";
import {CASE_FIELDS} from "../CaseList";
import {ClientInput} from "../../utils/ClientInput";
import {Flag} from "../../utils/Flag";
import { Box, Tab } from "@mui/material";
import {CitationSpecificFields, DOCUMENT_FIELDS, documentTypes} from "../../documents/documents";
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import {ConfirmInstructionsButton} from "./ConfirmInstructions";
import {MarkPaidButton} from "./MarkPaid";
import { Tabs } from "@mui/material";
import {instructionsExportToExcel} from "./instructions-exporter";
import moment from "moment";

const Filter = (props: any) => (
    <Filter {...props}>
        <TextInput source="instruction" alwaysOn></TextInput>
    </Filter>
);


const bulkActionButtons = <>
    {/*<BulkDeleteButton mutationMode="pessimistic" />*/}
    <ConfirmInstructionsButton></ConfirmInstructionsButton>
    <MarkPaidButton></MarkPaidButton>
</>;

export const InstructionsReceived = (props: any) => {
    const [value, setValue] = React.useState("one");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    }

    return (
        <Page title={"Instructions"}>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
            >
                <Tab
                    value="one"
                    label="Instructions Received"
                />
                <Tab value="two" label="Instructions Confirmed" />
                <Tab value="three" label="Instructions Paid/Completed" />
                <Tab value="all" label="ALL" />
            </Tabs>
            {
                value === "one" &&
                <List {...props}
                      sort={{ field: "created_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={{
                          "instruction@_neq": INSTRUCTION_WAIT,
                          "case_action#instructions_confirmed_by_payment_agent_at@_is_null": true,
                          "case_action#completed_at@_is_null": true,
                      }}
                      perPage={50}
                      exporter={instructionsExportToExcel}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                >
                    <Datagrid bulkActionButtons={
                        <>
                            <ConfirmInstructionsButton></ConfirmInstructionsButton>
                            <BulkExportButton></BulkExportButton>
                        </>
                    }>
                        {commonFields}
                        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                            <Box sx={{minWidth: "600px"}}>
                                <SimpleForm maxWidth={"sm"}>
                                    <TextInput source={"price_currency"} fullWidth />
                                    <NumberInput source={"official_fee"} fullWidth/>
                                    <NumberInput source={"our_fee"} fullWidth/>
                                    <NumberInput source={"agent_fee"} fullWidth/>
                                    <NumberInput source={"price"} fullWidth/>
                                </SimpleForm>
                            </Box>
                        </EditInDialogButton>
                    </Datagrid>
                </List>
            }

            {
                value === "two" &&
                <List {...props}
                      sort={{ field: "created_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={{
                          "instruction@_neq": INSTRUCTION_WAIT,
                          "case_action#instructions_confirmed_by_payment_agent_at@_is_null": false,
                          "case_action#completed_at@_is_null": true,
                }}
                      perPage={50}
                      exporter={instructionsExportToExcel}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                >
                    <Datagrid bulkActionButtons={
                        <>
                            <MarkPaidButton></MarkPaidButton>
                            <BulkExportButton></BulkExportButton>
                        </>
                    }>
                        {commonFields}
                        <DateField label="Confirmed at" source={"case_action.instructions_confirmed_by_payment_agent_at"} showTime />
                        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                            <Box sx={{minWidth: "600px"}}>
                                <SimpleForm maxWidth={"sm"}>
                                    <TextInput source={"price_currency"} fullWidth />
                                    <NumberInput source={"official_fee"} fullWidth/>
                                    <NumberInput source={"our_fee"} fullWidth/>
                                    <NumberInput source={"agent_fee"} fullWidth/>
                                    <NumberInput source={"price"} fullWidth/>
                                </SimpleForm>
                            </Box>
                        </EditInDialogButton>
                    </Datagrid>
                </List>
            }


            {
                value === "three" &&
                <List {...props}
                      sort={{ field: "created_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={{
                          "instruction@_neq": INSTRUCTION_WAIT,
                          "case_action#instructions_confirmed_by_payment_agent_at@_is_null": false,
                          "case_action#completed_at@_is_null": false,
                      }}
                      perPage={50}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                >
                    <Datagrid bulkActionButtons={false}>
                        {commonFields}
                        <DateField label="Confirmed at" source={"case_action.instructions_confirmed_by_payment_agent_at"} showTime />
                        <DateField label="Completed/paid at" source={"case_action.completed_at"} showTime />
                        <TextField source={"case_action.completion_notes"} />
                        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                            <Box sx={{minWidth: "600px"}}>
                                <SimpleForm maxWidth={"sm"}>
                                    <TextInput source={"price_currency"} fullWidth />
                                    <NumberInput source={"official_fee"} fullWidth/>
                                    <NumberInput source={"our_fee"} fullWidth/>
                                    <NumberInput source={"agent_fee"} fullWidth/>
                                    <NumberInput source={"price"} fullWidth/>
                                </SimpleForm>
                            </Box>
                        </EditInDialogButton>
                    </Datagrid>
                </List>
            }

            {
                value === "all" &&
                <List {...props}
                      sort={{ field: "created_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Client"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={{
                          // "instruction@_neq": INSTRUCTION_WAIT,
                      }}
                      perPage={50}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                >
                    <Datagrid bulkActionButtons={false}>
                        {commonFields}
                        <DateField label="Confirmed at" source={"case_action.instructions_confirmed_by_payment_agent_at"} showTime />
                        <DateField label="Completed/paid at" source={"case_action.completed_at"} showTime />
                        <TextField source={"case_action.completion_notes"} />
                        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                            <Box sx={{minWidth: "600px"}}>
                                <SimpleForm maxWidth={"sm"}>
                                    <TextInput source={"price_currency"} fullWidth />
                                    <NumberInput source={"official_fee"} fullWidth/>
                                    <NumberInput source={"our_fee"} fullWidth/>
                                    <NumberInput source={"agent_fee"} fullWidth/>
                                    <NumberInput source={"price"} fullWidth/>
                                </SimpleForm>
                            </Box>
                        </EditInDialogButton>
                    </Datagrid>
                </List>
            }


        </Page>
    );
};

const commonFields = [
    <TextField label="Client" source={"case_action.case.tenant.name"} ></TextField>,
    <TextField label={"Case ref"} source={"case_action.case.case_ref"} ></TextField>,
    <TextField label={"Client ref"} source={"case_action.case.tenant_case_ref"} ></TextField>,
    <FunctionField
        label="Country"
        sx={{ textWrap: "nowrap" }}
        render={(record: any) => record?.case_action?.case?.country_code ?
            <Box><Flag countryCode={record.case_action.case.country_code === "up" ? "eu" : record.case_action.case.country_code}></Flag>{"  " + countries[record.case_action.case.country_code]}</Box> : null
        }
    />,
    <TextField source="case_action.case.application_number" label={"Application number"} />,
    <TextField source="case_action.case.registration_number" label={"Registration number"}/>,
    <TextField source="case_action.action_rule.action_name" label={"Annuity"} />,
    <DateField source="case_action.due_date" label={"Due date"} />,
    <DateField source="case_action.with_fine_due_date" label={"Grace date"} />,
    <TextField source={"instruction"} ></TextField>,
    <FunctionField
        label="Price"
        sx={{ textWrap: "nowrap" }}
        render={(record: any) => record.price ? `${record.price} ${record.price_currency}` : "N/A" }
    />,
    <TextField source={"portal_price"}></TextField>,
    <TextField source={"user.name"} label={"Instructed by"}></TextField>,
    <DateField label="Instructed at" source={"created_at"} showTime />,
];
